export default {
  breakpoints: {
    tablet: "@media (min-width: 768px)",
    smallDesktop: "@media (min-width: 1024px)",
    desktop: "@media (min-width: 1280px)",
    largeDesktop: "@media (min-width: 1440px)",
  },
  fonts: {
    main1: "DM sans, sans-serif",
    main2: "Poppins, sans-serif",
  },
  mobile: {
    text: {
      size: "14px",
      weight: "400",
      lineHeight: "1.25",
    },
    subTitle: {
      weight: "700",
      size: "24px",
      lineHeight: "1.25",
    },
    title: {
      weight: "700",
      size: "32px",
      lineHeight: "1.25",
    },
  },
  smallDesktop: {
    mainTitle: {
      size: "48px",
    },
  },
  desktop: {
    text: {
      size: "20px",
      lineHeight: "1.5",
    },
    subTitle: {
      weight: "700",
      size: "32px",
      lineHeight: "1.25",
    },
    title: {
      weight: "700",
      size: "60px",
      lineHeight: "1",
    },
    mainTitle: {
      size: "72px",
      lineHeight: "70px",
    },
  },
  colors: {
    supporting: "rgba(17, 123, 175, 1)",
    basic: "rgba(0, 87, 120, 1)",
    basic2: "rgba(0, 174, 239, 1)",
    text1: "rgba(0, 87, 120, 1)",
    text2: "rgba(0, 174, 239)",
    text3: "rgba(255, 255, 255)",
    text4: "rgba(116, 93, 255, 1)",
    background1: "rgba(255, 255, 255)",
    background2: "rgba(238, 246, 255, 1)",
    background3: "rgba(0, 174, 239, 0.2)",
    background4: "rgba(116, 93, 255, 0.2)",
    buttons: {
      primary: {
        bg: "rgba(255, 144, 81, 1)",
        text: "rgba(255, 255, 255, 1)",
      },
      secondary: {
        bg: "rgba(255, 144, 81, 0.2)",
        text: "rgba(255, 120, 45, 1)",
      },
    },
  },
  gradient: {
    gradientBlue: "linear-gradient(180deg, #24C3FF 0%, #00ACEC 100%)",
    gradientLightBlue:
      "linear-gradient(180deg, #FFFFFF -56.16%, #E0F4FF 120.38%)",
    gradientViolet:
      "linear-gradient(359.08deg, #476DF5 -57.53%, #CD8DFF 163.11%);",
    gradientOrange: "linear-gradient(180deg, #FFA878 -15%, #FF8551 122.5%);",
    gradientGreen: "linear-gradient(180deg, #54EED9 -20.83%, #28D1BB 118.75%);",
  },
}
